// Component states
import states from './settings-image-carousel-modifier.state.js'
import workspace from '@/components/parts/workspace/workspace.state'

// Components
import ImagePathModifier from '../image-path-modifier/Image-path-modifier.vue'
import AlignHorizontalContentModifier from '../align-horizontal-content-modifier/Align-horizontal-content-modifier.vue'

// Assets
import image from '@/assets/images/placeholder-image.jpg'

// Services
import { debounce } from '@/services/utils/utils.service'
import { landingPageMode } from '@/services/states/states.service'

// Library
import {
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-image'

// Properties
const props = {
  mjml: Object,
  conf: Object
}

// Vue@data
const data = function () {
  return {
    states: _cloneDeep(states),
    workspace,
    image
  }
}
// Vue@data

// Components
const components = {
  ImagePathModifier,
  AlignHorizontalContentModifier
}

// Methods
const methods = {
  /**
   * Generate image with correct size
   * @param {String} src (url)
   */
  _checkImageSource (src) {
    const img = new Image()
    img.onload = () => {
      this.$set(this.states, 'onError', false)
    }
    img.onerror = () => {
      this.$set(this.states, 'onError', true)
    }
    img.src = src
  },

  /**
   * Set dirty field if url has been updated
   */
  setDirtyField () {
    this.$set(this.states, 'dirtyField', true)
  }
}

// Computed Methods
const computed = {

  /**
   * Check DND mode
   */
  isLandingMode () {
    return landingPageMode().get()
  },

  alternateText: {
    // getter
    get () {
      return this.mjml.attributes.alt.replace(/&quot;/g, '"')
    },
    // setter
    set (newValue) {
      const val = newValue.replace(/"/g, '&quot;') // Fix MJML issue with with `"` in html attr
      this.$set(this.mjml.attributes, 'alt', val)
    }
  },

  /**
   * Check image url before loading in UI
   * @return {String} (Image url or placeholder)
   */
  imageUrl () {
    const src = this.mjml.attributes.src
    return this.states.onError || !src ? image : src
  }
}

const watch = {
  'mjml.attributes.src': function () {
    this._checkImageSource(this.mjml.attributes.src)
    debounce({
      type: 'check-url',
      func: this.setDirtyField
    })
  }
}

function mounted () {
  this.states.settingsOpen = true
  this._checkImageSource(this.mjml.attributes.src)
}

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  computed,
  components,
  mounted
}
